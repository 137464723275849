import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import OperaGrid from '../../components/operagrid'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import BlockQuote from '../../components/blockquote'

const bibliography = [
  {name: 'Puccini Without Excuses: A Refreshing Reassessment of the World\'s Most Popular Composer', writer: 'William Berger', url: 'https://amzn.to/2WmgYPQ'},
  {name: 'Puccini: His Life and Works', writer: 'Julian Budden', url: 'https://amzn.to/3ydUzSh'},
  {name: 'The Lives of the Great Composers', writer: 'Harold C. Schonberg', url: 'https://amzn.to/3guutVb'},
];

const PucciniQuote = {content: 'Poetry, poetry, ardent affections, flesh, searching, almost surprising drama, fireworks finale.', quotee: 'Giacomo Puccini'}

const poperas = [
  {opera: 'Le Villi', premiere: '31st May 1884'},
  {opera: 'Edgar', premiere: '1st April 1889'},
  {opera: 'Manon Lescaut', premiere: '1st February 1893'},
  {opera: '<a href="/operas/boheme">La bohème</a>', premiere: '1st February 1896'},
  {opera: '<a href="/operas/tosca">Tosca</a>', premiere: '4th January 1900'},
  {opera: '<a href="/operas/butterfly">Madama Butterfly</a>', premiere: '17th February 1904'},
  {opera: 'La fanciulla del West', premiere: '10th December 1910'},
  {opera: 'La rondine', premiere: '27th March 1917'},
  {opera: 'Il trittico', premiere: '4th December 1918'},
  {opera: '<a href="/operas/turandot">Turandot</a>', premiere: '25th April 1926'},
];

// markup
const PucciniPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Giacomo Puccini | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/composers/puccini" />
          <meta name="description" content="A guide to the life and works of Giacomo Puccini! Explore his musical and dramatic genius." />
        </Helmet>
        <Hero title="Giacomo Puccini"/>
        <MainBodySection>

            <BlockQuote quote={PucciniQuote}/>

            <p>This is how Giacomo Puccini described his "genre" in a letter written in July 1894. It is a remarkably accurate roadmap to his school of composition and one for which he struggled to find unexplored territory throughout his life. He was the Titan of Opera from the late 19th Century to the early 1920s. Even today, on the rare occasions when opera bursts into the mainstream, it is often one of his works that does so. <em>Nessun Dorma</em>, as popularised by Luciano Pavarotti at the 1990 World Cup, is ripped straight from <Link to="/operas/turandot"><em>Turandot</em></Link>, Puccini’s final masterpiece.</p>

            
            <div id="ezoic-pub-ad-placeholder-102"> </div>

            <ImageFull>
              <StaticImage src="../../images/composers/puccini/Puccini_Piano.jpg" alt="Giacomo Puccini at the Piano"/>
              <figcaption>Giacomo Puccini at the Piano</figcaption>
            </ImageFull>

            <p>Across his 12 operas, an astonishing 7 of which are commonly performed works, he covered half the globe telling tales of Poor Parisian bohemians, Wild West cowboys and Chinese princesses amongst many others. Thrilling melodies, sumptuous orchestrations and gloriously melodramatic stories are a hallmark of his operas, making them fantastically engaging and endlessly moving bits of theatre.</p>

            <p>Popular for all the right reasons, there are few better places to start in opera than Puccini.</p>

            <Header title="His Operas"/>

            <OperaGrid rows={poperas}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Biography"/>

            <p>Born 22nd December 1858 in Lucca, Italy, music was in Puccini's blood, but he was not a wunderkind. Except for a handful of compositions, he was an opera composer, full stop, he never even conducted a single piece that he wrote. Puccini's father, among other duties, was director of the local conservatoire and church organist. When he died in 1864, Puccini's uncle succeeded him in those posts, although the six-year-old Giacomo was to take over as organist as soon as he was "able to discharge such duties," according to the official decree. Puccini never did take over, despite playing the organ and composing a few small pieces as a young man.</p>

            <ImageCenter>
              <StaticImage src="../../images/composers/puccini/LuccaCathedral_Tomoose.jpeg" alt="Lucca Cathedral"/>
              <figcaption>Lucca Cathedral &copy; <Link to="http://www.flickr.com/photos/tomoose/2825333735/">Tom Allan</Link></figcaption>
            </ImageCenter>

            <ImageRight>
              <StaticImage src="../../images/composers/puccini/Elvira.jpg" alt="Elvira Gemignani"/>
              <figcaption>Elvira Gemignani</figcaption>
            </ImageRight>

            <p>He saw his first opera, Verdi's <em>Aida</em>, at fifteen and "felt that a musical window had opened." He
            started composing larger works with an eye toward attending the Milan conservatoire, where he
            matriculated in 1880. His first taste of success came in 1884 with the one-act opera and ballet <em>Le
                willis</em> (later renamed <em>Le villi</em> and changed to two acts). One month later his mother
            died and Puccini almost immediately eloped with Elvira Gemignani, a married woman with whom he would
            have a stormy relationship for the rest of his life. (They eventually married in 1904.)</p>
        
            <p>Puccini never worked quickly, always searching for the right subject matter, the one that would "make
            people weep, therein lies everything." <em>Edgar</em>, his first full-length opera, premiered at La
            Scala in 1889, five years after <em>Le villi</em>. It failed, receiving just three performances, and has
            never entered the repertory (the last Met staging was in 1909 while its Vienna premiere was 2005).
            Puccini began a string of major successes four years later with <em>Manon Lescaut</em>, followed in 1896
            by <Link to="/operas/boheme">La bohème</Link> and in 1900 with <Link to="/operas/tosca">Tosca</Link>. This regularity was interrupted in February 1903
            by a car accident that left Puccini house bound for eight months. <em>Madama Butterfly</em> premiered
            just shy of the one-year anniversary of his car crash but was withdrawn immediately because of its poor
            reception.</p>
          
            <p>In 1909, before he could complete another opera, scandal engulfed Puccini when his wife, Elvira, was
              found guilty of "defamation of character, libel and menace to life and limb, relating to the suicide
              death of Doria Manfredi", the Puccinis' 16-year-old servant girl. Puccini was no saint (he had several
              affairs and according to one biographer, his "conquests were easy and numerous"), but he denied a
              relationship with Doria, whose virginity when she killed herself was verified posthumously. Upon appeal,
              the case was withdrawn after Puccini paid the girl's family 12,000 lire.</p>
        
            <p>His life never seemed to be particularly fulfilling after that point. He had some
                success with <em>La Fanciulla</em> and <em>Il trittico</em>, both of which premiered at the Met, and wrote some
                things that he didn't love, including <em>La rondine</em> and the choral work Inno a Roma ("a real piece of
                crap"). His last four years were haunted by a Proustian fear of death, lest he not complete his beloved <em>Turandot</em>.
                The fear proved well-founded. Puccini died of throat cancer in 1924 with <em>Turandot</em> unfinished.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Rivalry</FunFactsHeader>
    
            <p>Ruggero Leoncavallo is best known today for his one act opera, <em>I Paliacci</em>. Rather less well
                known is his <em>La Boheme</em>, thanks in no small part to the enormous success of Puccini’s
                version of the same story.</p>
      
            <p>Puccini first encountered Leoncavallo when he was brought in as one of several librettists for <em>Manon
                Lescaut</em>. Just before <em>Manon Lescaut</em> premiered, Puccini met with Leoncavallo and during
                their conversation Puccini revealed he was working on <em>La Boheme</em>. Leoncavallo was extremely
                angry, he too was working on a version using a libretto he had previously offered to Puccini, who
                had turned the offer down. Thus began a feud that would continue for the rest of their lives.</p>
      
            <p>Their operas premiered in adjacent years, Puccini’s first in 1896, and, as history would have it,
                Puccini’s work would be the one to endure.</p>
            
            <FunFactsHeader>Beginnings</FunFactsHeader>

            <ImageRight>
              <StaticImage src="../../images/composers/puccini/Prize.jpg" alt="The announcement of the Sonzogno prize"/>
              <figcaption>The announcement of the Sonzogno prize</figcaption>
            </ImageRight>
      
            <p>Puccini was encouraged to write his first opera “Le Willis” by his teacher Amilcare Ponchielli as an
                entry to the Sonzogno Competition in 1883. The competition was sponsored by a major music publisher
                of the time, Edoardo Sonzogno, the prize being 2000 lira and a performance of the winning opera in a
                theatre. Puccini didn’t win, indeed he didn’t even get an honorable mention. </p>
      
            <p>The two winning operas were <em>Anna e Gualberto</em> by Luigi Mapelli and <em>La Fata del Nord</em>
                by Guglielmo Zuelli (neither household names today!). The only real mark left on the world of opera
                by Sonzogno’s competition was the 1888 winner <Link to='/operas/cavalleria'>Cavalleria rusticana</Link> by Pietro Mascagni.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
                      
            <FunFactsHeader>Doria</FunFactsHeader>

            <ImageRight>
              <StaticImage src="../../images/composers/puccini/Doria_Manfredi.jpg" alt="Doria Manfredi"/>
              <figcaption>Doria Manfredi</figcaption>
            </ImageRight>

            <p>The tale of Doria Manfredi is a particularly unhappy one. Doria was just 16 years old when she was
                taken on by Puccini to help him recover from a car crash in 1903. She proved immensely useful and
                willing, staying with the Puccinis and becoming their full time domestic help.</p>
      
            <p>Suddenly in the fall of 1908, Elvira turned against her with a fury. Over the next few months Elvira
                destroyed Doria, firing her and then making her life a living hell in the village (including
                threatening to drown her). Doria poisoned herself on the 23rd of January 1909.</p>
      
            <p>Elvira’s reasons were, as you might expect, that she (wrongly) believed Doria was carrying on an
                affair with Puccini. The extremity of her actions however remains something of a mystery, Puccini
                had frequently betrayed Elvira over the course of their marriage and she had never reacted in
                anything like this manner. Perhaps it was because Doria was lower class or that she had taken over
                Elvira’s duties in the household, whatever the reason she was almost certainly wrong.</p>
            
            <FunFactsHeader>Family</FunFactsHeader>
      
            <p>Giacomo was born into a family that did little else but music. His father Michele was the city
                organist and choirmaster as well as a composer of religious music and a couple of operas (not
                successful). His mother, Albina, was a musician and it was her brother (i.e. Giacomo’s uncle)
                Fortunato Magi, also a musician, who initially taught Giacomo music. This family teaching didn’t go
                all that well however and Albina soon sent him to Carlo Angeloni for lessons. Angeloni wasn’t family
                as such but he was a former pupil of Michele!</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

          <AdContainer>
            <AdBanner
              style={{ display: 'block' }}
              slot="3461570696"
              format="auto"
              responsive="true"
            />
          </AdContainer>

          <MainBodySection>
            
            <FunFactsHeader>He Speaks</FunFactsHeader>

            <AudioSet audiosrc="/audio/composers/PSpeaks.mp3" desc="Puccini Speaks"/>
      
            <p>The brief audio above is the only known recording of Puccini, recorded in February 1907 in New York.
                A rough translation would be </p>
      
            <p>Puccini : <em>"Heartfelt thanks, to the distinguished Mr. Viafora, for all your kind words. I am
                really deeply grateful to the great public of New York for the very enthusiastic welcome they have
                given my operas. I accept your wishes for a safe journey, and conclude by cheering: America
                Forever!"</em></p>
      
            <p>Elvira : <em>I'm happy for the honours bestowed upon my husband, and I want to thank and say goodbye
                to everyone, especially to the American women that were so kind to us.</em></p>
            
            <FunFactsHeader>Opinions</FunFactsHeader>
            
            <p>In his 1981 Memoir, Lord Harewood (a fascinating man who deserves a page of his own) gives the
                following as a conversation between Benjamin Britten and Dmitri Shostakovich:</p>
      
            <p><em>Shostakovich</em>: What do you think of Puccini?</p>
      
            <p><em>Britten</em>: I think his operas are dreadful.</p>
      
            <p><em>Shostakovich</em>: No, Ben, you are wrong. He wrote marvellous operas, but dreadful music.</p>
      
            <p>Take what you will of this view!</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default PucciniPage
