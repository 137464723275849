import * as React from "react"
import "@fontsource/source-sans-pro"
import styled from 'styled-components'

// styles

const TopLevelGrid = styled.div`

  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;

  > div {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style: none;
    > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
    border-bottom: 1px solid #a34115;
    width: 50%;
    font-family: 'Source Sans Pro';

    h3 {
      color: #441600;
      font-size: 18px;
    }
  }
`;

// markup
const OperaGrid = (props) => {
  return (
    <TopLevelGrid>
      <div><h3>Opera</h3></div>
      <div><h3>Date of Premiere</h3></div>

      {props.rows.map(d => (
        <>
          <div dangerouslySetInnerHTML={{__html: d.opera}}/>
          <div>{d.premiere}</div>
        </>
      ))}

    </TopLevelGrid>
  )
}

export default OperaGrid